import React  from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FluidImage from "../../components/FluidImage"
import { Link } from "gatsby"

import styled from 'styled-components'

const Section = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 600px) {
    height: 90vh;
};
`

const Inner = styled.div`
  max-width: 66vw;
  width: 50vw;
  height: auto;
  @media screen and (max-width: 600px) {
      width: 80%;
      max-width: 80vw;
  };
`

const Nav = styled.div`
  display: grid;
  margin-top: 1rem;
  grid-template-columns: 1fr 1fr;
  justify-items: stretch;
  width: 100%;
  a {
      font-weight: 700;
      &:last-child {
          text-align: right;
      }
}
`


const Page = ({ pageContext }) => {

  const page = pageContext.page

  return (
    <Layout>
      <SEO title={page.title} />
       <Section>
        <Inner>
          <FluidImage image={page.home.image} />
          <Nav>
            <Link to="/info">Info</Link>
            <Link to="/projects">Doku</Link>
          </Nav>
        </Inner>
       </Section>
    </Layout>
  )
}

export default Page